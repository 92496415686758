
import MessageModal from "@/components/modals/geral/MessageModal.vue";
import { ref, onMounted, computed, Ref } from "vue";
import { Modal } from "bootstrap";
import distribuidorConfig from "@/core/config/DistribuidorConfig";
import usePDF from "@/composables/Pdf";
import useEmitter from "@/composables/Emmiter";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import useAlert from "@/composables/Alert";
import { useStore } from "vuex";
import { estoquePdf } from "@/services/Estoque";
import AuthService from "@/services/AuthService";

export default {
  name: "export-modal",
  components: {
    MessageModal,
    QuillEditor,
  },
  setup(props) {
    const store = useStore();
    const emitter = useEmitter();
    const importExcelView = ref(false);
    const concessionariaInfo: any = ref({});
    const concessionariaSelectedInfo = store.getters.concessionariaSelectedInfo;  
    const userInfo = AuthService.getUsuarioAuth();
    const nomeUser = userInfo.nome;
    const disabledButtonExportar = ref(false);
    const loadingExportPdf = ref(false);
    const loadingExportXLSX = ref(false);
    const showExportPdf = ref(true);
    const showExportXLSX = ref(true);
    const modalExport = ref();
    const modalShareEmail = ref();
    const contentResponse = ref();
    const produtos = ref({});
    const diasSegurancaEstoque = ref();
    const { showTimeAlert, showConfirmAlert } = useAlert();

    function reset() {
      showExportXLSX.value = true;
      showExportPdf.value = true;
      contentResponse.value = "";
    }

    store.watch(
      () => store.getters.concessionariaSelectedInfo,
      () => {
        concessionariaInfo.value = Object.assign(
          {},
          store.getters.concessionariaSelectedInfo
        );
      },
      { deep: true }
    );

    function buttonExport() {
      modalExport.value.show();
      importExcelView.value = true;
    }

    async function exportarRelatorio(exportFull = false) {
      disabledButtonExportar.value = true;
      const host = window.location.host;
      const distribuidor = distribuidorConfig.filter((e) => e.dominio_gestao == host)[0];

      let response;
      try {
        if (exportFull) {
          emitter.emit("geraPDF");
          loadingExportPdf.value = true;
          const dataValue = {
            produtos: produtos.value,
            concessionariaSelectedInfo: {
              nomeConcessionaria: concessionariaSelectedInfo.nomeFantasia,
              codAtria: concessionariaSelectedInfo.codAtria,
              diasSegurancaEstoque: diasSegurancaEstoque.value,
            },
            nomeUser,
            distribuidor: distribuidor.distribuidor,
          };
          response = await estoquePdf(dataValue);
          loadingExportPdf.value = false;
          showExportXLSX.value = false;
          showExportPdf.value = true;
        } else {
          showExportPdf.value = false;
        }

        contentResponse.value = response;
      } catch (e) {
        showTimeAlert("Não foi possível completar a solicitação", "error");
      } finally {
        loadingExportPdf.value = false;
        loadingExportXLSX.value = false;
        disabledButtonExportar.value = false;
      }
    }

    function exportarServico() {
      emitter.emit("geraServicoXLSX");
      modalExport.value.hide();
    }
    
    onMounted(() => {
      const refModal = document.getElementById("export-modal");
      if (refModal) {
        document.getElementById("layout_content")?.appendChild(refModal);
      }
      modalExport.value = new Modal(refModal);
    });

    return {
      showExportXLSX,
      showExportPdf,
      modalShareEmail,
      reset,
      loadingExportPdf,
      loadingExportXLSX,
      disabledButtonExportar,
      modalExport,
      exportarRelatorio,
      contentResponse,
      exportarServico,
      importExcelView,
      buttonExport,
    };
  },
};
